import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Col, DatePicker, Form, Input, notification, Row, Select } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Error, Loading } from '../../ui'
import dayjs from 'dayjs'

const i = {
	buttons: {
		update: 'Зберегти',
		back: 'Назад'
	},
	labels: {
		lastname: 'Прізвище',
		firstname: 'Ім\'я',
		patronymic: 'По-батькові',
		phone: 'Телефон',
		gender: 'Стать',
		birthday: 'День народження'
	},
	genders: {
		male: 'Чоловіча',
		female: 'Жіноча'
	},
	placeholders: {
		lastname: 'Введіть прізвище',
		firstname: 'Введіть ім\'я',
		patronymic: 'Введіть по-батькові',
		gender: 'Оберіть стать',
		birthday: 'День народження'
	},
	required: 'Це обов\'язкове поле!',
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!'
	}
}

export const Client = () => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const { data, loading, error, refetch } = useQuery(GET_CLIENT, { variables: { id }, fetchPolicy: 'network-only' })
	
	const [UpdateClient] = useMutation(UPDATE_CLIENT)
	
	const client = data?.getClient
	
	useEffect(() => {
		if (client) form.setFieldsValue({ ...client, birthday: !!client.birthday ? dayjs(client.birthday) : null })
	}, [client])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const update = values => {
		UpdateClient({
			variables: {
				id,
				...values,
				birthday: !!values.birthday ? values.birthday.hour(12).minute(0).second(0).toISOString() : null
			}
		}).then(() => {
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/clients')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				style={{ marginRight: 12 }}
				onClick={() => form.submit()}
			>
				{i.buttons.update}
			</Button>
			<Button
				type='default'
				onClick={() => navigate('/clients')}
			>
				{i.buttons.back}
			</Button>
		</div>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='client'
				layout='vertical'
				initialValues={{}}
				onFinish={update}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name='lastname'
							label={i.labels.lastname}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.lastname}/>
						</Form.Item>
						
						<Form.Item
							name='firstname'
							label={i.labels.firstname}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.firstname}/>
						</Form.Item>
						
						<Form.Item
							name='patronymic'
							label={i.labels.patronymic}
						>
							<Input placeholder={i.placeholders.patronymic}/>
						</Form.Item>
						
						<Form.Item
							name='phone'
							label={i.labels.phone}
						>
							<Input disabled/>
						</Form.Item>
						
						<Form.Item
							name='gender'
							label={i.labels.gender}
						>
							<Select placeholder={i.placeholders.gender}>
								<Select.Option value='male'>{i.genders.male}</Select.Option>
								<Select.Option value='female'>{i.genders.female}</Select.Option>
							</Select>
						</Form.Item>
						
						<Form.Item
							name='birthday'
							label={i.labels.birthday}
						>
							<DatePicker
								format='DD.MM.YYYY'
								placeholder={i.placeholders.birthday}
							/>
						</Form.Item>
					</Col>
					
					<Col span={12}>
					
					</Col>
				</Row>
			</Form>
		</Card>
	</div>
}

const GET_CLIENT = gql`
	query($id: ID) {
		getClient(id: $id) {
			id
			lastname
			firstname
			patronymic
			gender
			phone
			birthday
		}
	}
`

const UPDATE_CLIENT = gql`
	mutation ($id: ID, $lastname: String, $firstname: String, $patronymic: String, $gender: String, $birthday: Date) {
		updateClient(id: $id, lastname: $lastname, firstname: $firstname, patronymic: $patronymic, gender: $gender, birthday: $birthday) {
			id
		}
	}
`
