import React from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Empty, Table } from 'antd'
import { Loading, Error } from '../../ui'

const i = {
	columns: {
		title: 'ПІБ',
		phone: 'Телефон',
		gender: 'Стать',
		bonuses: 'Бонуси',
		code: 'Картка'
	},
	defaults: {
		gender: 'Не вказана',
		title: 'Не вказано'
	},
	genders: {
		male: 'Чоловіча',
		female: 'Жіноча'
	},
	empty: 'У Вас поки що немає клієнтів'
}

const createTitle = ({ lastname, firstname, patronymic = '' }) => {
	return (lastname && firstname)
		? `${lastname} ${firstname} ${patronymic}`
		: i.defaults.title
}

export const Clients = () => {
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const columns = [
		{
			title: i.columns.title,
			dataIndex: 'title',
			key: 'title',
			render: (_, record) => <Link to={`/clients/view?id=${record.id}`}>{createTitle(record)}</Link>
		},
		{
			title: i.columns.phone,
			dataIndex: 'phone',
			key: 'phone',
			render: phone => phone,
			align: 'center',
			width: 200
		},
		{
			title: i.columns.gender,
			dataIndex: 'gender',
			key: 'gender',
			render: gender => !!gender ? i.genders[gender] : i.defaults.gender,
			align: 'center',
			width: 200
		},
		{
			title: i.columns.code,
			dataIndex: 'code',
			key: 'code',
			render: code => !!code ? code : '---',
			align: 'center',
			width: 200
		},
		{
			title: i.columns.bonuses,
			dataIndex: 'bonuses',
			key: 'bonuses',
			render: bonuses => bonuses,
			align: 'center',
			width: 100
		}
	]
	
	const clients = data?.getClients
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		{!!clients.length ? <Table
			rowKey='id'
			dataSource={clients}
			columns={columns}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={i.empty}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getClients {
			id
			phone
			firstname
			lastname
			patronymic
			gender
			code
			bonuses
		}
	}
`
