import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { apollo } from './helpers'
import Routes from './Routes'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/uk_UA'
import dayjs from 'dayjs'
import 'dayjs/locale/uk'

dayjs.locale('uk')

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={apollo}>
			<ConfigProvider locale={locale}>
				<BrowserRouter>
					<Routes/>
				</BrowserRouter>
			</ConfigProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
