import React from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Empty, notification, Table } from 'antd'
import { Error, Loading } from '../../ui'
import dayjs from 'dayjs'

const i = {
	columns: {
		createdAt: 'Дата',
		phone: 'Телефон клієнта',
		provider: 'Провайдер',
		status: 'Статус',
		action: 'Дія'
	},
	statuses: {
		created: 'Створений',
		used: 'Використаний',
		skipped: 'Пропущений',
		confirmed: 'Підтверджений',
		duplicated: 'Дублікат'
		
	},
	buttons: {
		confirm: 'Підтвердити',
		disabled: 'Виконано'
	},
	notifications: {
		error: 'Помилка!',
		success: 'Успіх!'
	},
	empty: 'У Вас немає створених дзвінків'
}

export const Calls = () => {
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const [ConfirmCall] = useMutation(CONFIRM_CALL)
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const columns = [
		{
			title: i.columns.createdAt,
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: createdAt => dayjs(createdAt).format('DD.MM.YYYY HH:mm:ss')
		},
		{
			title: i.columns.phone,
			dataIndex: 'phone',
			key: 'phone',
			render: phone => phone
		},
		{
			title: i.columns.provider,
			dataIndex: 'provider',
			key: 'provider',
			render: provider => provider,
			width: 200
		},
		{
			title: i.columns.status,
			dataIndex: 'status',
			key: 'status',
			render: status => i.statuses[status],
			width: 200
		},
		{
			title: i.columns.action,
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => record.status === 'created'
				? <Button type='primary' onClick={() => confirm(record.id)}>{i.buttons.confirm}</Button>
				: <Button type='primary' disabled>{i.buttons.disabled}</Button>
			,
			width: 200
		}
	]
	
	const calls = data?.getCalls
	
	const confirm = id => {
		ConfirmCall({
			variables: { id },
			refetchQueries: [{ query: GETTER }]
		}).then(({ data: { confirmCall } }) => {
			if (confirmCall.status === 'success') {
				openNotification('success', i.notifications.success, confirmCall.message)
			}
			else openNotification('error', i.notifications.error, confirmCall.message)
		}).catch(e => {
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		{!!calls.length ? <Table
			rowKey='id'
			dataSource={calls}
			columns={columns}
			style={{ marginTop: 16 }}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={i.empty}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getCalls {
			id
			status
			phone
			provider
			createdAt
		}
	}
`

const CONFIRM_CALL = gql`
	mutation ($id: ID!) {
		confirmCall(id: $id) {
			id
			status
			message
		}
	}
`
