import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Flex, Form, Input, notification, Row } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { CloudServerOutlined, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Error, Loading } from '../../ui'

const i = {
	buttons: {
		update: 'Зберегти',
		synchronization: 'Синхронізація'
	},
	labels: {
		baseURL: 'Базова URL (1C)',
		login: 'Логін (1С)',
		password: 'Пароль (1С)'
	},
	placeholders: {
		baseURL: 'Введіть базову URL (1С)',
		login: 'Введіть логін (1С)',
		password: 'Введіть пароль (1С)'
	},
	required: 'Це обов\'язкове поле!',
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!'
	}
}

export const Settings = () => {
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ disabled: true, loading: false })
	
	const { data, loading, error, refetch } = useQuery(GET, { fetchPolicy: 'network-only' })
	const { data: dataSync, loading: loadingSync } = useQuery(CHECK_SYNCHRONIZATION, { pollInterval: 5000, notifyOnNetworkStatusChange: true })
	
	const [Update] = useMutation(UPDATE)
	const [CreateSynchronization] = useMutation(CREATE_SYNCHRONIZATION)
	
	const settings = data?.getSettings
	const checkSynchronizationStatus = dataSync?.checkSynchronization.status
	
	useEffect(() => {
		if (settings) {
			form.setFieldsValue({ ...settings })
		}
	}, [settings])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const update = values => {
		Update({
			variables: {
				...values
			}
		}).then(() => {
			openNotification('success', i.notifications.success, '')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const createSynchronization = () => {
		setState({ ...state, loading: true })
		
		CreateSynchronization()
			.then(({ data: { createSynchronization } }) => {
				if (createSynchronization.status === 'success') openNotification('success', i.notifications.success, '')
				else openNotification('error', i.notifications.error, createSynchronization.message)
			})
			.catch(e => {
				console.log(e)
				openNotification('error', i.notifications.error, e.message)
				setState({ ...state, loading: false })
			})
	}
	
	useEffect(() => {
		if (!loadingSync) {
			if (checkSynchronizationStatus) {
				if (checkSynchronizationStatus === 'success') {
					if (state.disabled || state.loading) setState({ disabled: false, loading: false })
				}
				else {
					if (state.disabled || !state.loading) setState({ disabled: false, loading: true })
				}
			}
		}
	}, [loadingSync])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<Flex gap='small' wrap>
			<Button
				type='primary'
				onClick={() => form.submit()}
			>
				{i.buttons.update}
			</Button>
			<Button
				type='primary'
				ghost
				loading={state.loading}
				disabled={state.disabled}
				onClick={() => createSynchronization()}
			>
				{i.buttons.synchronization}
			</Button>
		</Flex>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='settings'
				layout='vertical'
				initialValues={undefined}
				onFinish={update}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name='baseURL'
							label={i.labels.baseURL}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.baseURL} prefix={<CloudServerOutlined/>}/>
						</Form.Item>
						
						<Form.Item
							name='login'
							label={i.labels.login}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.login} prefix={<UserOutlined/>}/>
						</Form.Item>
						
						<Form.Item
							name='password'
							label={i.labels.password}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.Password prefix={<LockOutlined/>} type='password' placeholder={i.placeholders.password}/>
						</Form.Item>
					
					</Col>
				</Row>
			</Form>
		</Card>
	</div>
}

const GET = gql`
	query {
		getSettings {
			id
			baseURL
			login
			password
		}
	}
`

const CHECK_SYNCHRONIZATION = gql`
	query {
		checkSynchronization {
			id
			status
			message
		}
	}
`

const UPDATE = gql`
	mutation ($baseURL: String, $login: String, $password: String) {
		updateSettings(baseURL: $baseURL, login: $login, password: $password) {
			id
		}
	}
`

const CREATE_SYNCHRONIZATION = gql`
	mutation {
		createSynchronization {
			id
			status
			message
		}
	}
`
