import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Button, Empty, Flex, Table } from 'antd'
import { Error, Loading } from '../../ui'
import dayjs from 'dayjs'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const i = {
	columns: {
		name: 'Назва',
		active: 'Активність',
		date: 'Дата розсилки'
	},
	button: {
		create: 'Створити',
		statistics: 'Статистика'
	},
	empty: 'У Вас поки що немає push-розсилок'
}


export const Pushes = () => {
	const navigate = useNavigate()
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const columns = [
		{
			title: i.columns.name,
			dataIndex: 'name',
			key: 'name',
			render: (name, record) => <Link to={`/pushes/edit?id=${record.id}`}>{name}</Link>
		},
		{
			title: i.columns.active,
			dataIndex: 'active',
			key: 'active',
			align: 'center',
			render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>,
			width: 200
		},
		{
			title: i.columns.date,
			dataIndex: 'date',
			key: 'date',
			render: date => dayjs(date).format('DD.MM.YYYY HH:mm:ss'),
			align: 'center',
			width: 200
		}
	]
	
	const pushes = data?.getPushJobs
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<Flex gap='small' wrap>
			<Button
				type='primary'
				onClick={() => navigate('/pushes/add')}
			>
				{i.button.create}
			</Button>
			<Button
				onClick={() => {}}
			>
				{i.button.statistics}
			</Button>
		</Flex>
		
		{!!pushes.length ? <Table
			rowKey='id'
			dataSource={pushes}
			columns={columns}
			style={{ marginTop: 16 }}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={i.empty}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getPushJobs {
			id
			name
			active
			date
		}
	}
`
