import React, { useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb, Dropdown, Layout, Menu, Space } from 'antd'
import { BookOutlined, CalendarOutlined, ContactsOutlined, HomeOutlined, PhoneOutlined, SendOutlined, SettingOutlined, TeamOutlined } from '@ant-design/icons'

const i = {
	sidebar: {
		news: 'Новини',
		clients: 'Клієнти',
		contacts: 'Контакти',
		calls: 'Дзвінки',
		information: 'Інформація',
		pushes: 'Push-розсилки',
		settings: 'Налаштування'
	},
	dropdown: {
		settings: 'Налаштування',
		exit: 'Вихід'
	},
	support: 'Технічна підтримка'
}

const breadcrumbsMap = {
	'/news': 'Новини',
	'/news/add': 'Створення новини',
	'/news/edit': 'Редагування новини',
	'/clients': 'Клієнти',
	'/clients/view': 'Перегляд клієнта',
	'/calls': 'Дзвінки',
	'/contacts': 'Контакти',
	'/contacts/add': 'Створення контакту',
	'/contacts/edit': 'Редагування контакту',
	'/pushes': 'Push-розсилки',
	'/pushes/add': 'Створення push-розсилки',
	'/pushes/edit': 'Редагування push-розсилки',
	'/settings': 'Налаштування',
	'/information': 'Інформація'
}

export const Main = () => {
	const year = new Date().getFullYear()
	
	const [collapsed, setCollapsed] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()
	
	const pathSnippets = location.pathname.split('/').filter(i => i)
	const breadcrumbs = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
		return <Breadcrumb.Item key={url}>
			{pathSnippets.length - 1 === index ? breadcrumbsMap[url] : <Link to={url}>{breadcrumbsMap[url]}</Link>}
		</Breadcrumb.Item>
	})
	
	const onClickMenu = ({ key }) => {
		if (key) navigate(key)
	}
	
	const onClickDropdown = ({ key }) => {
		if (key === 'settings') navigate('/settings')
		else if (key === 'exit') {
			localStorage.removeItem('@token')
			navigate('/login')
		}
	}
	
	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Layout.Sider
				style={{ boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)' }}
				theme='light'
				collapsible
				collapsed={collapsed}
				onCollapse={() => setCollapsed(!collapsed)}
			>
				{!collapsed && <img src={'/images/logo.svg'} style={{ width: '168px', margin: '16px' }} alt=''/>}
				<Menu
					theme='light'
					mode='inline'
					defaultSelectedKeys={[location.pathname]}
					inlineIndent={20}
					onClick={onClickMenu}
					items={[
						{ key: '/news', label: i.sidebar.news, icon: <CalendarOutlined/> },
						{ key: '/clients', label: i.sidebar.clients, icon: <TeamOutlined/> },
						{ key: '/calls', label: i.sidebar.calls, icon: <PhoneOutlined /> },
						{ key: '/contacts', label: i.sidebar.contacts, icon: <ContactsOutlined/> },
						{ key: '/information', label: i.sidebar.information, icon: <BookOutlined/> },
						{ key: '/pushes', label: i.sidebar.pushes, icon: <SendOutlined /> },
						{ key: '/settings', label: i.sidebar.settings, icon: <SettingOutlined/> },
					]}
				/>
			</Layout.Sider>
			<Layout>
				<Layout.Header style={{ backgroundColor: '#ffffff', padding: '0 20px', display: 'flex', justifyContent: 'space-between' }}>
					<div/>
					<Space>
						<Dropdown
							menu={{
								items: [
									{ key: 'settings', label: i.dropdown.settings },
									{ key: 'exit', label: i.dropdown.exit, danger: true }
								],
								onClick: onClickDropdown
							}}
						>
							<div style={{ display: 'flex', alignItems: 'center', height: '64px', padding: '0 12px', cursor: 'pointer', transition: 'all .3s' }}>
								<SettingOutlined style={{ fontSize: '20px', color: '#1890ff' }}/>
							</div>
						</Dropdown>
					</Space>
				</Layout.Header>
				
				<Breadcrumb style={{ padding: '28px 24px 0' }}>
					<Breadcrumb.Item>
					<HomeOutlined/>
					</Breadcrumb.Item>
					{breadcrumbs}
				</Breadcrumb>
				
				<Layout.Content>
					<Outlet/>
				</Layout.Content>
				<Layout.Footer style={{ textAlign: 'center' }}>IDEAL FLORA © {year} {i.support} <a href='https://appsbox.pro' target='_blank'>appsbox.pro</a></Layout.Footer>
			</Layout>
		</Layout>
	)
}
