import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Row, Col, Form, Input, InputNumber, Switch, notification, Popconfirm, Image, Upload } from 'antd'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import ImageCrop from 'antd-img-crop'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { Loading, Error } from '../../ui'
import { isDev } from '../../helpers'
import config from '../../config'

const i = {
	buttons: {
		create: 'Створити',
		update: 'Зберегти',
		remove: 'Видалити',
		back: 'Назад'
	},
	labels: {
		active: 'Активність',
		title: 'Назва новини',
		description: 'Опис новини',
		sort: 'Сортування'
	},
	placeholders: {
		title: 'Введіть назву новини',
		description: 'Введіть опис новини'
	},
	popup: {
		title: 'Підтвердьте видалення новини',
		cancel: 'Відміна',
		ok: 'Видалити'
	},
	required: 'Це обов\'язкове поле!',
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!',
		create: 'Новина успішно створена!',
		update: 'Новина успішно збережена!',
		remove: 'Новина успішно видалена!'
	},
	image: {
		info: 'Ідеальне співвідношення сторін 1:1 (наприклад, 750:750). Формат JPG або PNG',
		upload: 'Завантаження зображення',
		modal: {
			title: 'Редагування зображення',
			ok: 'Завантажити',
			cancel: 'Відміна',
			reset: 'Скасувати зміни'
		}
	}
}

export const New = ({ type }) => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ image: '', fetching: true, creating: false, updating: false, removing: false, imaging: false })
	
	const [Get, { data, error, refetch }] = useLazyQuery(GET, { fetchPolicy: 'network-only' })
	
	const [Create] = useMutation(CREATE)
	const [Update] = useMutation(UPDATE)
	const [Remove] = useMutation(REMOVE)
	
	const news = data?.getNew
	
	useEffect(() => {
		if (news) setState({ ...state, fetching: false, image: news.image })
	}, [news])
	
	useEffect(() => {
		if (type === 'edit') {
			Get({ variables: { id } }).catch(e => console.log(e))
		} else setState({ ...state, fetching: false })
	}, [])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const create = values => {
		setState({ ...state, creating: true })
		Create({
			variables: {
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				image: state.image
			}
		}).then(() => {
			setState({ ...state, creating: false })
			openNotification('success', i.notifications.success, i.notifications.create)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, creating: false })
		})
	}
	
	const update = values => {
		setState({ ...state, updating: true })
		Update({
			variables: {
				id,
				title: values.title,
				description: values.description,
				active: values.active,
				sort: values.sort || 0,
				image: state.image
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const remove = () => {
		setState({ ...state, removing: true })
		Remove({
			variables: {
				id
			}
		}).then(() => {
			setState({ ...state, removing: false })
			openNotification('success', i.notifications.success, i.notifications.remove)
			navigate('/news')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, removing: false })
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (state.fetching) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				style={{ marginRight: 12 }}
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => form.submit()}
			>
				{type === 'edit' ? i.buttons.update : i.buttons.create}
			</Button>
			{type === 'edit' && <Popconfirm
				placement='top'
				title={i.popup.title}
				okText={i.popup.ok}
				cancelText={i.popup.cancel}
				okButtonProps={{ danger: true }}
				cancelButtonProps={{ danger: true }}
				onConfirm={remove}
			>
				<Button
					style={{ marginRight: 12 }}
					danger
					loading={state.removing}
					disabled={state.removing || state.creating || state.updating}
				>
					{i.buttons.remove}
				</Button>
			</Popconfirm>}
			<Button
				type='default'
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => navigate('/news')}
			>
				{i.buttons.back}
			</Button>
		</div>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='news'
				layout='vertical'
				initialValues={type === 'edit' ? news : undefined}
				onFinish={type === 'edit' ? update : create}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name='active' label={i.labels.active} valuePropName='checked'>
							<Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
						</Form.Item>
						
						<Form.Item
							name='title'
							label={i.labels.title}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.title}/>
						</Form.Item>
						
						<Form.Item name='description' label={i.labels.description}>
							<Input.TextArea rows={4} placeholder={i.placeholders.description}/>
						</Form.Item>
						
						<Form.Item name='sort' label={i.labels.sort}>
							<InputNumber type='number' precision={0}/>
						</Form.Item>
					</Col>
					
					<Col span={12}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
							<ImageCrop
								aspect={1}
								quality={1}
								maxZoom={5}
								modalTitle={i.image.modal.title}
								modalOk={i.image.modal.ok}
								modalCancel={i.image.modal.cancel}
								resetText={i.image.modal.reset}
								showGrid
								rotationSlider
								showReset
								modalWidth={720}
							>
								<Upload
									listType={'picture'}
									maxCount={1}
									showUploadList={false}
									action={`${isDev ? config.server.development : config.server.production}/images/upload`}
									onChange={({ file: { response } }) => {
										if (response) setState({ ...state, image: response.filename })
									}}
								>
									<Button icon={<UploadOutlined/>}>{i.image.upload}</Button>
								</Upload>
							</ImageCrop>
							<div style={{ marginLeft: 12, color: '#595959' }}>{i.image.info}</div>
						</div>
						
						{!!state.image && <Image src={`${isDev ? config.server.development : config.server.production}/images/${state.image}`}/>}
					</Col>
				</Row>
			</Form>
		</Card>
	</div>
}

const GET = gql`
	query($id: ID!) {
		getNew(id: $id) {
			id
			active
			title
			description
			image
			sort
		}
	}
`

const CREATE = gql`
	mutation ($active: Boolean, $title: String, $description: String, $image: String, $sort: Int) {
		createNew(active: $active, title: $title, description: $description, image: $image, sort: $sort) {
			id
		}
	}
`

const UPDATE = gql`
	mutation ($id: ID!, $active: Boolean, $title: String, $description: String, $image: String, $sort: Int) {
		updateNew(id: $id, active: $active, title: $title, description: $description, image: $image, sort: $sort) {
			id
		}
	}
`

const REMOVE = gql`
	mutation ($id: ID!) {
		removeNew(id: $id) {
			id
		}
	}
`
