import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Button, Empty, Table } from 'antd'
import { Error, Loading } from '../../ui'

const i = {
	columns: {
		title: 'Назва',
		type: 'Тип',
		sort: 'Сортування'
	},
	types: {
		phone: 'Телефон',
		address: 'Адреса',
		email: 'E-mail',
		web: 'Web-сайт',
		instagram: 'Instagram',
		whatsapp: 'WhatsApp',
		viber: 'Viber',
		telegram: 'Telegram',
		facebook: 'Facebook'
	},
	empty: 'У Вас немає створених контактів',
	button: {
		create: 'Створити'
	}
}

export const Contacts = () => {
	const navigate = useNavigate()
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const columns = [
		{
			title: i.columns.title,
			dataIndex: 'title',
			key: 'title',
			render: (title, record) => <Link to={`/contacts/edit?id=${record.id}`}>{title}</Link>
		},
		{
			title: i.columns.type,
			dataIndex: 'type',
			key: 'type',
			render: type => i.types[type],
			width: 200
		},
		{
			title: i.columns.sort,
			dataIndex: 'sort',
			key: 'sort',
			render: sort => sort,
			width: 200
		}
	]
	
	const contacts = data?.getContacts
	
	const goAdd = () => navigate('/contacts/add')
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button type='primary' onClick={goAdd}>
				{i.button.create}
			</Button>
		</div>
		
		{!!contacts.length ? <Table
			rowKey='id'
			dataSource={contacts}
			columns={columns}
			style={{ marginTop: 16 }}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={i.empty}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getContacts {
			id
			type
			title
			description
			source
			sort
		}
	}
`
