import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Image, Input, InputNumber, notification, Row, Upload } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Error, Loading } from '../../ui'
import { isDev } from '../../helpers'
import config from '../../config'
import ImageCrop from 'antd-img-crop'
import { UploadOutlined } from '@ant-design/icons'

const i = {
	buttons: {
		update: 'Зберегти'
	},
	headers: {
		about: 'Про нас',
		sidebar: 'Бокове меню',
		main: 'Основні налаштування'
	},
	labels: {
		about: {
			description: 'Інформація про нас'
		},
		
		main: {
			currency: 'Валюта',
			timezone: 'Часовий пояс сервера'
		},
		sidebar: {
			bonuses: 'Умови бонусної програми',
			legal: 'Чинне законодавство',
			confidentiality: 'Політика конфіденційності'
		}
	},
	placeholders: {
		about: {
			description: 'Введіть інформацію про нас'
		},
		main: {
			currency: 'Введіть валюту'
		},
		sidebar: {
			bonuses: 'Введіть інформацію',
			legal: 'Введіть інформацію',
			confidentiality: 'Введіть інформацію'
		}
	},
	required: 'Це обов\'язкове поле!',
	image: {
		info: 'Ідеальне співвідношення сторін 5:3 (наприклад, 750:450). Формат JPG або PNG',
		upload: 'Завантаження зображення',
		modal: {
			title: 'Редагування зображення',
			ok: 'Завантажити',
			cancel: 'Відміна',
			reset: 'Скасувати зміни'
		}
	},
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!'
	}
}

const rows = 6

export const Information = () => {
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ image: '' })
	const { data, loading, error, refetch } = useQuery(GET, { fetchPolicy: 'network-only' })
	
	const [Update] = useMutation(UPDATE)
	
	const information = data?.getInformation
	
	useEffect(() => {
		if (information) {
			form.setFieldsValue({ ...information })
			setState({ ...state, image: information.about.image })
		}
	}, [information])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const update = values => {
		Update({
			variables: {
				...values,
				about: {
					...values.about,
					image: state.image
				}
			}
		}).then(() => {
			openNotification('success', i.notifications.success, i.notifications.update)
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				style={{ marginRight: 12 }}
				onClick={() => form.submit()}
			>
				{i.buttons.update}
			</Button>
		</div>
		
		<Form
			form={form}
			name='information'
			layout='vertical'
			initialValues={undefined}
			onFinish={update}
		>
			<Card style={{ marginTop: 16 }} title={i.headers.main}>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name={['main', 'timezone']}
							label={i.labels.main.timezone}
							rules={[{ required: true, message: i.required }]}
						>
							<InputNumber type='number' precision={0}/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={['main', 'currency']}
							label={i.labels.main.currency}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.main.currency}/>
						</Form.Item>
					</Col>
				</Row>
			</Card>
			
			<Card style={{ marginTop: 16 }} title={i.headers.about}>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							name={['about', 'description']}
							label={i.labels.about.description}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.TextArea rows={5} placeholder={i.placeholders.about.description}/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
							<ImageCrop
								aspect={1.6667}
								quality={1}
								maxZoom={5}
								modalTitle={i.image.modal.title}
								modalOk={i.image.modal.ok}
								modalCancel={i.image.modal.cancel}
								resetText={i.image.modal.reset}
								showGrid
								rotationSlider
								showReset
								modalWidth={720}
							>
								<Upload
									listType={'picture'}
									maxCount={1}
									showUploadList={false}
									action={`${isDev ? config.server.development : config.server.production}/images/upload`}
									onChange={({ file: { response } }) => {
										if (response) setState({ ...state, image: response.filename })
									}}
								>
									<Button icon={<UploadOutlined/>}>{i.image.upload}</Button>
								</Upload>
							</ImageCrop>
							<div style={{ marginLeft: 12, color: '#595959' }}>{i.image.info}</div>
						</div>
						{!!state.image && <Image src={`${isDev ? config.server.development : config.server.production}/images/${state.image}`}/>}
					</Col>
				</Row>
			</Card>
			
			<Card style={{ marginTop: 16 }} title={i.headers.sidebar}>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name={['sidebar', 'bonuses']}
							label={i.labels.sidebar.bonuses}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.TextArea rows={rows} placeholder={i.placeholders.sidebar.bonuses}/>
						</Form.Item>
						
						<Form.Item
							name={['sidebar', 'legal']}
							label={i.labels.sidebar.legal}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.TextArea rows={rows} placeholder={i.placeholders.sidebar.legal}/>
						</Form.Item>
						
						<Form.Item
							name={['sidebar', 'confidentiality']}
							label={i.labels.sidebar.confidentiality}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.TextArea rows={rows} placeholder={i.placeholders.sidebar.confidentiality}/>
						</Form.Item>
					</Col>
				</Row>
			</Card>
		</Form>
	
	</div>
}

const GET = gql`
	query {
		getInformation {
			id
			main {
				timezone
				currency
			}
			about {
				image
				description
			}
			sidebar {
				confidentiality
				bonuses
				legal
			}
		}
	}
`

const UPDATE = gql`
	mutation ($main: InformationMainInput, $about: InformationAboutInput, $sidebar: InformationSidebarInput) {
		updateInformation(main: $main, about: $about, sidebar: $sidebar) {
			id
		}
	}
`
