import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Col, DatePicker, Flex, Form, Image, Input, InputNumber, notification, Popconfirm, Row, Select, Space, Switch, Upload } from 'antd'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Error, Loading } from '../../ui'
import ImageCrop from 'antd-img-crop'
import { isDev } from '../../helpers'
import config from '../../config'
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const i = {
	buttons: {
		create: 'Створити',
		update: 'Зберегти',
		remove: 'Видалити',
		back: 'Назад'
	},
	headers: {
		criteria: 'Критерії підбору аудиторії',
		date: 'Налаштування дати розсилки'
	},
	labels: {
		active: 'Активність',
		name: 'Назва (для вас)',
		title: 'Заголовок повідомлення',
		body: 'Текст повідомлення',
		ttl: 'Термін доступності (секунд)',
		link: 'Зовнішнє посилання (URL) не обов\'язково',
		expires: 'Автовидалення через (днів)',
		type: 'Тип повідомлення',
		date: 'Дата розсилки',
		criteria: {
			age: 'Вік клієнта (від-до)',
			bonuses: 'Бонуси (від-до)',
			
			clientsPhones: 'Телефони клієнтів',
			clientsType: 'Тип клієнтів',
			genderType: 'Стать клієнта'
		}
	},
	selections: {
		types: {
			all: 'Всі клієнти',
			authorized: 'Авторизовані',
			unauthorized: 'Неавторизовані'
		},
		genders: {
			all: 'Всі',
			male: 'Чоловіки',
			female: 'Жінки'
		}
	},
	placeholders: {
		name: 'Введіть назву push-розсилки (для вас)',
		title: 'Введіть заголовок повідомлення для клієнта',
		body: 'Введіть текст повідомлення для клієнта',
		link: 'Посилання має бути у форматі https://',
		criteria: {
			clientsPhones: 'Розділювач кома, формат міжнародний: +380991002030, +380995006070'
		}
	},
	popup: {
		title: 'Підтвердьте видалення push-розсилки',
		cancel: 'Відміна',
		ok: 'Видалити'
	},
	required: 'Це обов\'язкове поле!',
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!',
		create: 'Push-розсилка успішно створена!',
		update: 'Push-розсилка успішно збережена!',
		remove: 'Push-розсилка успішно видалена!'
	},
	image: {
		info: 'Ідеальне співвідношення сторін 1:1 (наприклад, 750:750). Формат JPG або PNG',
		upload: 'Завантаження зображення',
		modal: {
			title: 'Редагування зображення',
			ok: 'Завантажити',
			cancel: 'Відміна',
			reset: 'Скасувати зміни'
		}
	}
}

export const Push = ({ type }) => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ image: '', fetching: true, creating: false, updating: false, removing: false })
	
	const [Get, { data, error, refetch }] = useLazyQuery(GET, { fetchPolicy: 'network-only' })
	
	const [Create] = useMutation(CREATE)
	const [Update] = useMutation(UPDATE)
	const [Remove] = useMutation(REMOVE)
	
	const push = data?.getPushJob
	
	useEffect(() => {
		if (push) setState({ ...state, image: push.image, fetching: false })
	}, [push])
	
	useEffect(() => {
		if (type === 'edit') Get({ variables: { id } }).catch(e => console.log(e))
		else setState({ ...state, fetching: false })
	}, [])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const create = values => {
		setState({ ...state, creating: true })
		Create({
			variables: {
				...values,
				image: state.image,
				date: values.date.toDate()
			}
		}).then(() => {
			setState({ ...state, creating: false })
			openNotification('success', i.notifications.success, i.notifications.create)
			navigate('/pushes')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, creating: false })
		})
	}
	
	const update = values => {
		setState({ ...state, updating: true })
		Update({
			variables: {
				id,
				...values,
				image: state.image,
				date: values.date.toDate()
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/pushes')
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const remove = () => {
		setState({ ...state, removing: true })
		Remove({
			variables: {
				id
			}
		}).then(() => {
			setState({ ...state, removing: false })
			openNotification('success', i.notifications.success, i.notifications.remove)
			navigate('/pushes')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, removing: false })
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (state.fetching) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<Flex gap='small' wrap>
			<Button
				type='primary'
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => form.submit()}
			>
				{type === 'edit' ? i.buttons.update : i.buttons.create}
			</Button>
			{type === 'edit' && <Popconfirm
				placement='top'
				title={i.popup.title}
				okText={i.popup.ok}
				cancelText={i.popup.cancel}
				okButtonProps={{ danger: true }}
				cancelButtonProps={{ danger: true }}
				onConfirm={remove}
			>
				<Button
					danger
					loading={state.removing}
					disabled={state.removing || state.creating || state.updating}
				>
					{i.buttons.remove}
				</Button>
			</Popconfirm>}
			<Button
				type='default'
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => navigate('/pushes')}
			>
				{i.buttons.back}
			</Button>
		</Flex>
		<Form
			form={form}
			name='pushes'
			layout='vertical'
			initialValues={type === 'edit'
				? {
					...push,
					date: dayjs(push.date)
				}
				: {
					active: true,
					name: '', title: '', body: '', link: '', ttl: 86400, expires: 30,
					criteria: { ageFrom: 0, ageTo: 0, bonusesFrom: 0, bonusesTo: 0, clientsPhones: '', clientsType: 'all', genderType: 'all' },
					date: dayjs().add(1, 'day')
				}
			}
			onFinish={type === 'edit' ? update : create}
		>
			<Card style={{ marginTop: 16 }}>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name='active' label={i.labels.active} valuePropName='checked'>
							<Switch checkedChildren={<CheckOutlined/>} unCheckedChildren={<CloseOutlined/>}/>
						</Form.Item>
						
						<Form.Item
							name='name'
							label={i.labels.name}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.name}/>
						</Form.Item>
						
						<Form.Item
							name='title'
							label={i.labels.title}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.title}/>
						</Form.Item>
						
						<Form.Item
							name='body'
							label={i.labels.body}
							rules={[{ required: true, message: i.required }]}
						>
							<Input.TextArea rows={4} placeholder={i.placeholders.body}/>
						</Form.Item>
						
						<Form.Item
							name='link'
							label={i.labels.link}
						>
							<Input placeholder={i.placeholders.link}/>
						</Form.Item>
						
						<Form.Item
							name='ttl'
							label={i.labels.ttl}
							rules={[{ required: true, message: i.required }]}
						>
							<InputNumber id='ttl' type='number' min={1} precision={0}/>
						</Form.Item>
						
						<Form.Item
							name='expires'
							label={i.labels.expires}
							rules={[{ required: true, message: i.required }]}
						>
							<InputNumber id='expires' type='number' min={1} precision={0}/>
						</Form.Item>
					
					</Col>
					
					<Col span={12}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
							<ImageCrop
								aspect={1}
								quality={1}
								maxZoom={5}
								modalTitle={i.image.modal.title}
								modalOk={i.image.modal.ok}
								modalCancel={i.image.modal.cancel}
								resetText={i.image.modal.reset}
								showGrid
								rotationSlider
								showReset
								modalWidth={720}
							>
								<Upload
									listType={'picture'}
									maxCount={1}
									showUploadList={false}
									action={`${isDev ? config.server.development : config.server.production}/images/upload`}
									onChange={({ file: { response } }) => {
										if (response) setState({ ...state, image: response.filename })
									}}
								>
									<Button icon={<UploadOutlined/>}>{i.image.upload}</Button>
								</Upload>
							</ImageCrop>
							<div style={{ marginLeft: 12, color: '#595959' }}>{i.image.info}</div>
						</div>
						
						{!!state.image && <Image src={`${isDev ? config.server.development : config.server.production}/images/${state.image}`}/>}
					</Col>
				</Row>
			</Card>
			
			<Card style={{ marginTop: 16 }} title={i.headers.criteria}>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item
							name={['criteria', 'clientsPhones']}
							label={i.labels.criteria.clientsPhones}
							rules={[{ required: false }]}
						>
							<Input.TextArea rows={5} placeholder={i.placeholders.criteria.clientsPhones}/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item dependencies={[['criteria', 'clientsPhones']]}>
							{({ getFieldValue }) => <Form.Item
								name={['criteria', 'clientsType']}
								label={i.labels.criteria.clientsType}
								rules={[{ required: false }]}
							>
								<Select disabled={!!getFieldValue(['criteria', 'clientsPhones'])}>
									<Select.Option value='all'>{i.selections.types.all}</Select.Option>
									<Select.Option value='authorized'>{i.selections.types.authorized}</Select.Option>
									<Select.Option value='unauthorized'>{i.selections.types.unauthorized}</Select.Option>
								</Select>
							</Form.Item>}
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item dependencies={[['criteria', 'clientsPhones'], ['criteria', 'clientsType']]}>
							{({ getFieldValue }) => {
								const disabled = !!getFieldValue(['criteria', 'clientsPhones']) || getFieldValue(['criteria', 'clientsType']) !== 'authorized'
								return <>
									<Form.Item
										name={['criteria', 'genderType']}
										label={i.labels.criteria.genderType}
										rules={[{ required: false }]}
									>
										<Select disabled={disabled}>
											<Select.Option value='all'>{i.selections.genders.all}</Select.Option>
											<Select.Option value='male'>{i.selections.genders.male}</Select.Option>
											<Select.Option value='female'>{i.selections.genders.female}</Select.Option>
										</Select>
									</Form.Item>
									
									<Form.Item label={i.labels.criteria.age}>
										<Space.Compact>
											<Form.Item
												name={['criteria', 'ageFrom']}
												noStyle
											>
												<InputNumber disabled={disabled} type='number' min={0} precision={0} style={{ width: '50%' }}/>
											</Form.Item>
											<Form.Item
												name={['criteria', 'ageTo']}
												noStyle
											>
												<InputNumber disabled={disabled} type='number' min={0} precision={0} style={{ width: '50%' }}/>
											</Form.Item>
										</Space.Compact>
									</Form.Item>
									
									<Form.Item label={i.labels.criteria.bonuses}>
										<Space.Compact>
											<Form.Item
												name={['criteria', 'bonusesFrom']}
												noStyle
											>
												<InputNumber disabled={disabled} type='number' min={0} precision={0} style={{ width: '50%' }}/>
											</Form.Item>
											<Form.Item
												name={['criteria', 'bonusesTo']}
												noStyle
											>
												<InputNumber disabled={disabled} type='number' min={0} precision={0} style={{ width: '50%' }}/>
											</Form.Item>
										</Space.Compact>
									</Form.Item>
								</>
							}}
						</Form.Item>
					</Col>
				</Row>
			
			</Card>
			
			<Card style={{ marginTop: 16 }} title={i.headers.date}>
				<Form.Item
					label={i.labels.date}
					name='date'
					rules={[{
						required: true,
						message: i.required
					}]}
				>
					<DatePicker showTime/>
				</Form.Item>
			</Card>
		</Form>
	</div>
}

const GET = gql`
	query($id: ID!) {
		getPushJob(id: $id) {
			id
			active
			name
			title
			body
			image
			ttl
			link
			expires
			type
			date
			criteria {
				ageFrom
				ageTo
				bonusesFrom
				bonusesTo
				clientsPhones
				clientsType
				genderType
			}
		}
	}
`

const CREATE = gql`
	mutation ($active: Boolean, $name: String, $title: String, $body: String, $image: String, $ttl: Int, $link: String, $expires: Int, $type: String, $date: Date, $criteria: PushJobCriteriaInput) {
		createPushJob(active: $active, name: $name, title: $title, body: $body, image: $image, ttl: $ttl, link: $link, expires: $expires, type: $type, date: $date, criteria: $criteria) {
			id
		}
	}
`

const UPDATE = gql`
	mutation ($id: ID!, $active: Boolean, $name: String, $title: String, $body: String, $image: String, $ttl: Int, $link: String, $expires: Int, $type: String, $date: Date, $criteria: PushJobCriteriaInput) {
		updatePushJob(id: $id, active: $active, name: $name, title: $title, body: $body, image: $image, ttl: $ttl, link: $link, expires: $expires, type: $type, date: $date, criteria: $criteria) {
			id
		}
	}
`

const REMOVE = gql`
	mutation ($id: ID!) {
		removePushJob(id: $id) {
			id
		}
	}
`
