import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Checkbox } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'

const i = {
	placeholder: {
		email: 'Email',
		password: 'Пароль'
	},
	button: {
		save: 'Запам\'ятати',
		auth: 'Увійти'
	},
	error: {
		client: {
			email: 'Введіть Ваш email!',
			password: 'Введіть Ваш пароль!'
		},
		server: {
			email: 'Невірний email',
			password: 'Невірний пароль'
		}
	}
}

export const Login = () => {
	const [form] = Form.useForm()
	const [AuthUser] = useMutation(AUTH_USER)
	const navigate = useNavigate()
	
	const onFinish = values => {
		AuthUser({
			variables: {
				email: values.email,
				password: values.password
			}
		}).then(({ data: { authUser: { jwt } } }) => {
			localStorage.setItem('@token', jwt)
			navigate('/news')
		}).catch(error => {
			form.setFields([{
				name: error.message,
				errors: [i.error.server[error.message]]
			}])
		})
	}
	
	useEffect(() => {
		const token = localStorage.getItem('@token')
		if (token) navigate('/news')
	}, [])
	
	return (
		<Row type='flex' align='middle' justify='center' style={{ height: '100vh' }}>
			<Col>
				<img src={'/images/logo.svg'} style={{ width: 200, height: 30, display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '24px' }} alt=''/>
				<Form
					form={form}
					style={{ width: '300px' }}
					name='basic'
					initialValues={{ remember: true }}
					onFinish={onFinish}
					autoComplete='off'
				>
					<Form.Item
						name='email'
						rules={[
							{
								required: true,
								message: i.error.client.email
							}
						]}
					>
						<Input
							prefix={<MailOutlined/>}
							placeholder={i.placeholder.email}
						/>
					</Form.Item>
					
					<Form.Item
						name='password'
						rules={[
							{
								required: true,
								message: i.error.client.password
							}
						]}
					>
						<Input.Password
							prefix={<LockOutlined/>}
							type='password'
							placeholder={i.placeholder.password}
						/>
					</Form.Item>
					
					<Form.Item
						name='remember'
						valuePropName='checked'
					>
						<Checkbox>{i.button.save}</Checkbox>
					</Form.Item>
					
					<Form.Item>
						<Button type='primary' htmlType='submit' style={{ width: '100%' }}>
							{i.button.auth}
						</Button>
					</Form.Item>
				
				</Form>
			</Col>
		</Row>
	)
}

const AUTH_USER = gql`
	mutation ($email: String, $password: String) {
		authUser(email: $email, password: $password) {
			id
			jwt
		}
	}
`
