import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Card, Col, Form, Input, InputNumber, notification, Popconfirm, Row, Select } from 'antd'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { Error, Loading } from '../../ui'

const i = {
	buttons: {
		create: 'Створити',
		update: 'Зберегти',
		remove: 'Видалити',
		back: 'Назад'
	},
	labels: {
		type: 'Тип',
		title: 'Назва контакту',
		description: 'Опис контакту',
		source: 'Джерело контакту',
		sort: 'Сортування'
	},
	placeholders: {
		type: 'Оберіть тип',
		title: 'Введіть назву контакту',
		description: 'Введіть опис контакту',
		source: 'Введіть джерело контакту'
	},
	types: {
		phone: 'Телефон',
		address: 'Адреса',
		email: 'E-mail',
		web: 'Web-сайт',
		instagram: 'Instagram',
		whatsapp: 'WhatsApp',
		viber: 'Viber',
		telegram: 'Telegram',
		facebook: 'Facebook'
	},
	popup: {
		title: 'Підтвердьте видалення контакту',
		cancel: 'Відміна',
		ok: 'Видалити'
	},
	required: 'Це обов\'язкове поле!',
	notifications: {
		success: 'Успіх!',
		error: 'Помилка!',
		create: 'Контакт успішно створено!',
		update: 'Контакт успішно збережено!',
		remove: 'Контакт успішно видалено!'
	}
}

export const Contact = ({ type }) => {
	const navigate = useNavigate()
	
	const [params] = useSearchParams()
	const id = params.get('id')
	const [form] = Form.useForm()
	
	const [state, setState] = useState({ fetching: true, creating: false, updating: false, removing: false })
	
	const [Get, { data, error, refetch }] = useLazyQuery(GET, { fetchPolicy: 'network-only' })
	
	const [Create] = useMutation(CREATE)
	const [Update] = useMutation(UPDATE)
	const [Remove] = useMutation(REMOVE)
	
	const contact = data?.getContact
	
	useEffect(() => {
		if (contact) setState({ ...state, fetching: false })
	}, [contact])
	
	useEffect(() => {
		if (type === 'edit') Get({ variables: { id } }).catch(e => console.log(e))
		else setState({ ...state, fetching: false })
	}, [])
	
	const openNotification = (type, message, description) => notification[type]({ message, description })
	
	const create = values => {
		setState({ ...state, creating: true })
		Create({
			variables: {
				type: values.type,
				title: values.title,
				description: values.description,
				source: values.source,
				sort: values.sort || 0
			}
		}).then(() => {
			setState({ ...state, creating: false })
			openNotification('success', i.notifications.success, i.notifications.create)
			navigate('/contacts')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, creating: false })
		})
	}
	
	const update = values => {
		setState({ ...state, updating: true })
		Update({
			variables: {
				id,
				type: values.type,
				title: values.title,
				description: values.description,
				source: values.source,
				sort: values.sort || 0
			}
		}).then(() => {
			setState({ ...state, updating: false })
			openNotification('success', i.notifications.success, i.notifications.update)
			navigate('/contacts')
		}).catch(e => {
			console.log(e)
			setState({ ...state, updating: false })
			openNotification('error', i.notifications.error, e.message)
		})
	}
	
	const remove = () => {
		setState({ ...state, removing: true })
		Remove({
			variables: {
				id
			}
		}).then(() => {
			setState({ ...state, removing: false })
			openNotification('success', i.notifications.success, i.notifications.remove)
			navigate('/contacts')
		}).catch(e => {
			console.log(e)
			openNotification('error', i.notifications.error, e.message)
			setState({ ...state, removing: false })
		})
	}
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (state.fetching) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button
				type='primary'
				style={{ marginRight: 12 }}
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => form.submit()}
			>
				{type === 'edit' ? i.buttons.update : i.buttons.create}
			</Button>
			{type === 'edit' && <Popconfirm
				placement='top'
				title={i.popup.title}
				okText={i.popup.ok}
				cancelText={i.popup.cancel}
				okButtonProps={{ danger: true }}
				cancelButtonProps={{ danger: true }}
				onConfirm={remove}
			>
				<Button
					style={{ marginRight: 12 }}
					danger
					loading={state.removing}
					disabled={state.removing || state.creating || state.updating}
				>
					{i.buttons.remove}
				</Button>
			</Popconfirm>}
			<Button
				type='default'
				loading={type === 'edit' ? state.updating : state.creating}
				disabled={type === 'edit' ? (state.updating || state.removing) : state.creating}
				onClick={() => navigate('/contacts')}
			>
				{i.buttons.back}
			</Button>
		</div>
		
		<Card style={{ marginTop: 16 }}>
			
			<Form
				form={form}
				name='news'
				layout='vertical'
				initialValues={type === 'edit' ? contact : undefined}
				onFinish={type === 'edit' ? update : create}
			>
				<Row gutter={16}>
					<Col span={12}>
						
						<Form.Item
							name='type'
							label={i.labels.type}
							rules={[{ required: true, message: i.required }]}
						>
							<Select placeholder={i.placeholders.type}>
								<Select.Option value='phone'>{i.types.phone}</Select.Option>
								<Select.Option value='address'>{i.types.address}</Select.Option>
								<Select.Option value='web'>{i.types.web}</Select.Option>
								<Select.Option value='email'>{i.types.email}</Select.Option>
								<Select.Option value='instagram'>{i.types.instagram}</Select.Option>
								<Select.Option value='whatsapp'>{i.types.whatsapp}</Select.Option>
								<Select.Option value='viber'>{i.types.viber}</Select.Option>
								<Select.Option value='telegram'>{i.types.telegram}</Select.Option>
								<Select.Option value='facebook'>{i.types.facebook}</Select.Option>
							</Select>
						</Form.Item>
						
						<Form.Item
							name='title'
							label={i.labels.title}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.title}/>
						</Form.Item>
						
						<Form.Item
							name='description'
							label={i.labels.description}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.description}/>
						</Form.Item>
						
						<Form.Item
							name='source'
							label={i.labels.source}
							rules={[{ required: true, message: i.required }]}
						>
							<Input placeholder={i.placeholders.source}/>
						</Form.Item>
						
						<Form.Item name='sort' label={i.labels.sort}>
							<InputNumber type='number' precision={0}/>
						</Form.Item>
					</Col>
					
					<Col span={12}>
					
					</Col>
				</Row>
			</Form>
		</Card>
	</div>
}

const GET = gql`
	query($id: ID!) {
		getContact(id: $id) {
			id
			type
			title
			description
			source
			sort
		}
	}
`

const CREATE = gql`
	mutation ($type: String, $title: String, $description: String, $source: String, $sort: Int) {
		createContact(type: $type, title: $title, description: $description, source: $source, sort: $sort) {
			id
		}
	}
`

const UPDATE = gql`
	mutation ($id: ID, $type: String, $title: String, $description: String, $source: String, $sort: Int) {
		updateContact(id: $id, type: $type, title: $title, description: $description, source: $source, sort: $sort) {
			id
		}
	}
`

const REMOVE = gql`
	mutation ($id: ID) {
		removeContact(id: $id) {
			id
		}
	}
`
