import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Empty, Button, Table } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Loading, Error } from '../../ui'

const i = {
	columns: {
		title: 'Назва',
		active: 'Активність'
	},
	empty: 'У Вас немає створених новин',
	button: {
		create: 'Створити'
	}
}

export const News = () => {
	const navigate = useNavigate()
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	
	const columns = [
		{
			title: i.columns.title,
			dataIndex: 'title',
			key: 'title',
			render: (title, record) => <Link to={`/news/edit?id=${record.id}`}>{title}</Link>
		},
		{
			title: i.columns.active,
			dataIndex: 'active',
			key: 'active',
			align: 'center',
			render: active => active ? <CheckCircleOutlined style={{ color: '#52c41a' }}/> : <CloseCircleOutlined style={{ color: '#ff4d4f' }}/>,
			width: 200
		}
	]
	
	const news = data?.getNews
	
	const goAdd = () => navigate('/news/add')
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading) return <Loading/>
	
	return <div style={{ padding: '28px 24px 0' }}>
		<div>
			<Button type='primary' onClick={goAdd}>
				{i.button.create}
			</Button>
		</div>
		
		{!!news.length ? <Table
			rowKey='id'
			dataSource={news}
			columns={columns}
			style={{ marginTop: 16 }}
		
		/> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={i.empty}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getNews {
			id
			active
			image
			title
			description
			sort
		}
	}
`
